.spinner {
  display: flex;
  justify-content: center;
  height: 50vh;
  align-items: center;
}

.spinner > span > svg {
  color: var(--captic-main);
}
