.error-page {
  height: 100%;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-page > p {
  margin-top: 2rem;
}

#issue-image {
  width: 30%;
  margin-bottom: 3rem;
}
