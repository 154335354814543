.uc {
  display: flex;
  height: 100%;
}

.uc-left {
  display: flex;
  flex-direction: column;
  margin: -2rem 1rem -2rem -2rem;
  justify-content: space-around;
  background-color: white;
  align-items: left;
}

.uc-left-step {
  flex: 1;
  width: 100%;
  padding: 0.5rem;
  border-bottom: 2px solid var(--captic-grey);
  border-left: 2px solid var(--captic-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  text-align: center;
}

.uc-left-step-active {
  color: var(--captic-main);
}

.uc-left-step-disabled {
  cursor: not-allowed;
}

.uc-right {
  flex: 1;
}
