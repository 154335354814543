.header {
  display: flex;
  background: var(--captic-main);
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  border-bottom: 1px solid var(--captic-grey);
}

.header-logo {
  height: 2.5rem;
  margin: 0.2rem 1rem;
}
