* {
  margin: 0;
}

:root {
  --captic-main: rgb(53 120 246);
  --captic-darkmain: rgb(48 101 199);
  --captic-grey: #f6f8fa;
}

/* stylelint-disable */
body {
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, "Menlo", "Monaco", "Consolas", "Courier New",
    monospace;
}
/* stylelint-enable */

a:hover {
  color: inherit;
}
