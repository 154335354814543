.table {
  margin-top: 3rem;
  background-color: white;
}

.table-status,
.table-status-green {
  color: #727171 !important;
  border: 2px solid #727171 !important;
  font-weight: bold;
}

.table-status-green {
  color: #4caf50 !important;
  border: 2px solid #4caf50 !important;
}

/* MUI specific */
/* stylelint-disable */
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.MuiDataGrid-cell {
  background-color: white !important;
  outline: none !important;
}
/* stylelint-enable */