.crud-body {
  display: flex;
  margin-top: 1rem;
}

.crud-card {
  background: white;
  height: fit-content;
  width: fit-content;
  min-width: 28vw;
  margin: 1rem;
  margin-bottom: 0;
  padding: 1rem;
}

.crud-infotable-row > td {
  padding-bottom: 1rem !important;
}

.crud-infotable-title {
  font-weight: 600;
  padding-right: 1rem;
}
