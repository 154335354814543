.actionbutton-space {
  margin-right: 5rem !important;
}

/* stylelint-disable */
.Mui-disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
}
/* stylelint-enable */
