.box-card > table > tr > td {
  padding: 0.5rem;
}

.box-card-select {
  width: 100%;
}

/* stylelint-disable */
.MuiFormControl-root {
  width: 100%;
}

.MuiFormControlLabel-label {
  font-size: 0.9rem !important;
}
/* stylelint-enable */
