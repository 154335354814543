.navlink {
  text-decoration: none;
  color: inherit;
}

.navlink-text {
  padding-left: 0.5rem;
  border-left: 2px solid transparent;
}

.navlink-text-active {
  border-left: 2px solid var(--captic-main) !important;
}
