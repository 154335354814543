.app {
  background: var(--captic-grey);
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-body {
  flex: 1;
  display: flex;
}

.app-body-right {
  flex: 1;
  margin: 2rem;
}

.app-unknown {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 30%;
}

.app-unknown > p {
  margin-top: 2rem;
}
