.pageheader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pageheader-buttons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
