.nav {
  background: #fff;
  padding: 2rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 12vw;
}

.nav-footer {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: rgb(71 71 71);
}

.nav-footer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.nav-footer > p {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
