.label-body {
  display: flex;
}

.label-slider {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  max-height: 80vh;
  margin-right: 1rem;
}

.label-thumbnail {
  width: 150px;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  border-left: 5px solid var(--captic-grey);
}

.label-thumbnail-active {
  border-left: 5px solid var(--captic-main);
}

/* Label Studio specific */
#label-studio {
  width: fit-content;
  max-height: 65vw;
}

#label-studio * {
  max-height: inherit;
}

.ls-menu {
  margin-top: 4.5rem;
}

/* stylelint-disable */
.Segment_block__1fyeG {
  border: none;
  background-color: var(--captic-grey);
}
/* stylelint-enable */

.ls-entity-buttons > button:nth-child(-n + 2) {
  display: none;
}

.ant-divider,
.ant-card-body > p,
.ant-dropdown-trigger {
  display: none !important;
}

.ant-tree-list {
  max-height: 15rem !important;
}

/* stylelint-disable */
.Panel_block__controls__psq4W > button:last-child {
  display: none !important;
  margin-bottom: 0;
}

.Panel_common__2RaTo {
  display: none !important;
}
/* stylelint-enable */
